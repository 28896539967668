<template>
    <modal
        class="auth-modal"
        :adaptive="true"
        :scrollable="true"
        :click-to-close="allowClose"
        name="auth-modal"
        :max-width="500"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <notifications
            :ignore-duplicates="true"
            :max="1"
            group="auth-notifications"
            class="auth-notifications"
        />
        <div v-if="allowClose" class="allow-close" @click="closeAuthModal">
            <img src="@assets/img/icons/x.svg" alt="Close modal">
        </div>
        <component
            :is="currentAuthComponent"
            @set-auth-form="(value) => currentAuthComponent = value"
            @auth-sucessfull="signInSuccessful"
            @is-loading="(value) => isLoading = value"
        />
        <fullscreen-loader v-if="isLoading" />
    </modal>
</template>

<script>
export default {
    name: "AuthModal",
    components: {
        SignInForm: () => import(/* webpackChunkName: "sign-in-form"*/ "./sign-in-form"),
        SignUpForm: () => import(/* webpackChunkName: "sign-up-form"*/ "./sign-up-form"),
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader")
    },
    data: () => ({
        currentAuthComponent: "sign-in-form",
        allowClose: false,
        redirectRoute: null,
        isLoading: false
    }),
    methods: {
        dispatchAmplitudeAuthEvent() {
            const user = this.$store.state.User.data;
            this.$amplitude.logEvent(this.currentAuthComponent === "sign-in-form" ? "Logging" : "SignUp", { name: user.displayname }, user?.id?.toString());
        },
        signInSuccessful() {
            this.$nextTick(() => {
                this.$store.dispatch("Application/getGlobalStateData").then(() => {
                    this.dispatchAmplitudeAuthEvent();
                    if (this.redirectRoute) {
                        this.$router.push(this.redirectRoute);
                    } else {
                        this.$modal.hide("auth-modal");
                    }
                });
            });
        },
        closeAuthModal() {
            this.$emit("closed-auth-modal");
            this.$modal.hide("auth-modal");
        },
        beforeOpen(event) {
            if (event.params?.allowClose) {
                this.allowClose = true;
            }

            if (event.params?.showSignIn) {
                this.currentAuthComponent = "sign-in-form";
            }

            if (event.params?.showSignUp) {
                this.currentAuthComponent = "sign-up-form";
            }

            if (event.params?.redirectRoute) {
                this.redirectRoute = event.params?.redirectRoute;
            }

            this.$emit("before-open");
        },
        beforeClose() {
            this.allowClose = false;
            this.$emit("before-close");
        }
    }
}
</script>

<style lang="scss" scoped>
.auth-modal {
    /deep/ .vm--modal {
        background-color: #202022;
        box-shadow: none;
        border-radius: 30px;

        .allow-close {
            display: flex;
            position: absolute;
            right: 18px;
            top: 18px;
            width: 15px;
            height: 15px;
            cursor: pointer;
            z-index: 2;

            img {
                width: 100%;
            }
        }

        .auth-notifications {
            position: absolute;
            width: 100% !important;
            z-index: 1;

            .vue-notification-wrapper {
                .vue-notification-template {
                    margin: 0;
                    border-left: 0;
                    padding: 15px 0;
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>